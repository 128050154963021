$media: screen;
$feature_min: min-width;
$feature_max: max-width;
$value_one: 576px;
$value_one_2: 577px;
$value_two: 767px;
$value_three: 768px;
$value_four: 991px;
$value_five: 992px;
$value_six: 1199px;
$value_six_2: 1200px;
$value_seven_2: 1549px;
$value_seven: 1550px;
$value_eight: 1620px;
$value_nine: 1920px;
$background-color: #F3F6F9;
$main-color: #6c3478;

@media screen and (-webkit-min-device-pixel-ratio: 2) {
    select,
    select:focus,
    textarea,
    textarea:focus,
    input,
    input:focus {
      font-size: 17px !important;
    }
}




@media only #{$media} and ($feature_max : 420px) {

    
    .hide-380{
        display: none !important
      
    }

    
    
}


@media only #{$media} and ($feature_min : $value_seven) {

    
    .grid-home{
        display: grid;
        grid-template-columns: repeat(6, minmax(0px, 1fr));
        grid-gap: 10px;
      
    }

    .grid-home > div {
            flex: 1 1 0px;

        }
    


}

@media only #{$media} and ($feature_min : $value_one_2) {

    
    .meta-articles{
        display: flex;
      
    }


}





@media only #{$media} and ($feature_min : $value_six_2) and ($feature_max : $value_seven_2) {

    
    .grid-home{
        display: grid;
        grid-template-columns: repeat(6, minmax(0px, 1fr));
        grid-gap: 10px;
        .hd{
            display: none !important;
        }
    }


}

@media only #{$media} and ($feature_max : $value_two) {

    
    .grid-home{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 10px;
        
        .hd-2{
            display: none !important;
        }
        .hd{
            display: none !important;
        }
    }


}


@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    .dispensary-home-deals{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 10px;
        
    }
    .grid-home{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 10px;
        
    }


}


@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    
    .grid-home{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 10px;
        
    }




}

@media only #{$media} and ($feature_max : $value_three) {

    .carrousel-title{
        h1{
            font-size: 23px;
        }
    }


    .dispensary-home-deals{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        grid-gap: 10px;
        
    }

}


@media only #{$media} and ($feature_max : $value_four) {


    .products-size-wrapper{
        margin-top: 0 !important;
        display: flex !important;
        flex-direction: row !important;
        align-items: center;
        ul{
            margin-top: 0px !important;
            margin-left: 0px !important; 
}
        
        span{
            margin-bottom: 0 !important;
        }

        .mt-3{
           
        }
    }


    .w-60m{
        width: 60%;
    }

    .page-title-content{
        padding-top: 5px !important;
    }

    .products-details-desc{
        margin-top: 10px !important;
    }

    .grid-retailers{
        padding-top: 15px !important;
    }

    #RETAILERS{
        height: 10px !important;
    }
    
    .homeSlider{
        &.articles-home{
            .grid-home{
                display: grid !important;
                grid-template-columns: repeat(1, minmax(0px, 1fr)) !important;
                grid-gap: 20px 10px;
                .hd{
                    display: none !important;
                }
            }
        }
        
    }


}

@media only #{$media} and ($feature_min : $value_five) {

    
   
    .dispensary-home-deals{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 10px;
        
    }
    
    .homeSlider{
        &.articles-home{
            .grid-home{
                display: grid !important;
                grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
                grid-gap: 20px 10px;
                .hd{
                    display: none !important;
                }
            }
        }
        
    }


}






@media only #{$media} and ($feature_min : $value_six_2) and ($feature_max : $value_seven_2) {
    .col-featured{
        -webkit-box-flex: 0;
        -ms-flex: 0 25%;
        flex: 0 25%;
        max-width: 25%;
    
    }
    
    
    .col-featured-side{
        -webkit-box-flex: 0;
        -ms-flex: 0 75%;
        flex: 0 75%;
        max-width: 75%;
    
    }
}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {

    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 1em
    }

}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    .cards-grid-list-extended{
        display: grid;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .home-deals-grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1em;
    }

    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1em
    }

}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {
    .cards-grid-list-extended{
        display: grid;
        grid-template-columns: repeat(5, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .aside-filter{
        min-width: 250px !important;
        width: 250px !important;
    }
    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1em
    }

}

@media only #{$media} and ($feature_min : 1200px) and ($feature_max : 1549px) {

    .aside-filter{
        width: 350px !important;
        min-width: 350px !important;

        &.small{
            width: 300px !important;
        min-width: 300px !important;
        }
    }

    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1em
    }

}

@media only #{$media} and ($feature_min : $value_seven) {

    
    .product-details-area{
        grid-template-columns: repeat(20, minmax(0px, 1fr));
        .grid-product{
            grid-column: 1 / 16 !important;
        }
        .grid-retailers{
            grid-column: 16 / 21 !important;
        }
    }

    .aside-filter{
        width: 350px !important;
        min-width: 350px !important;
    }

    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        grid-gap: 1em
    }

}







//GRID CONFIGURATION
@media only #{$media} and ($feature_max : $value_six_2) {

    .hide-1200{
        display: none !important;
    }
}

@media only #{$media} and ($feature_min : $value_six_2) {
    .cards-grid-list-extended{
        display: grid;
        grid-template-columns: repeat(7, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .mySwipera{
        .swiper-wrapper{
            max-height: 280px !important;
        }
    }

    .product-details-area{
        grid-template-columns: repeat(20, minmax(0px, 1fr));
        .grid-product{
            grid-column: 1 / 16 !important;
        }
        .grid-retailers{
            grid-column: 16 / 21 !important;
        }
    }
}



@media only #{$media} and ($feature_min : $value_three) {
    .carrousel-title{
        h1{
            font-size: 34px;
        }
    }


    .d-flex-desk{
        display: flex !important;
    }


    .brand-grid{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-gap:1rem 1rem;
            gap: 1rem 1rem;
            margin-bottom: 1rem
    }

    .cards-carrousel.padding-full-screen{
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .grid-categories{
        overflow: hidden;
        border-radius: 6px;
        .badge{
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 5;
        }
        .category{
            cursor: pointer;
            transition: all 0.175s ease-in-out;
            overflow: hidden;
            img{
                transition: all 0.5s;
            }
            &:hover{
                img{
                    transform: scale(1.07);
                    transition: all 0.5s;
                }
            }
            img{
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            &:nth-child(1){
                width: 40%;
                height: 500px;
                float: left;
                position: relative;
                // padding-right: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(2){
                .badge{
                    left: calc(8px + .425rem);
                }
                width: 35%;
                height: 500px;
                float: left;
                position: relative;
                // padding-left: .425rem;
                // padding-right: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(4){
                .badge{
                    left: calc(8px + .425rem);
                    top: calc(8px + .425rem);
                }
                width: 25%;
                height: 400px;
                float: right;
                position: relative;
                // padding-left: .425rem;
                // padding-top: .425rem
            
            }
            &:nth-child(3){
                width: 25%;
                height: 350px;
                .badge{
                    left: calc(8px + .425rem);
                }
                float: left;
                position: relative;
                // padding-left: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(5){
                .badge{
                    top: calc(8px + .425rem);
                }
                width: 75%;
                height: 250px;
                float: left;
                position: relative;
                // padding-right: .425rem;
                // padding-top: .425rem
            }
        }
    }
    .d-none-md-up{
        display: none !important;
    }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_six) {

    .grid-categories{
        overflow: hidden;
        border-radius: 6px;
        .badge{
            position: absolute;
            top: 8px;
            left: 8px;
        }
        .category{
            cursor: pointer;
            transition: all 0.175s ease-in-out;
            img{
                transition: all 0.5s;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.07);
                    transition: all 0.5s;
                }
            }
            // &:hover{
            //     transform: scale(1.025);
            //     transition: all 0.175s ease-in-out;
            // }
            &:nth-child(1){
                width: 40%;
                height: 350px;
                float: left;
                position: relative;
                // padding-right: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(2){
                .badge{
                    left: calc(8px + .425rem);
                }
                width: 35%;
                height: 350px;
                float: left;
                position: relative;
                // padding-left: .425rem;
                // padding-right: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(4){
                .badge{
                    left: calc(8px + .425rem);
                    top: calc(8px + .425rem);
                }
                width: 25%;
                height: 280px;
                float: right;
                position: relative;
                // padding-left: .425rem;
                // padding-top: .425rem
               
            }
            &:nth-child(3){
                width: 25%;
                height: 245px;
                .badge{
                    left: calc(8px + .425rem);
                }
                float: left;
                position: relative;
                // padding-left: .425rem;
                // padding-bottom: .425rem
            }
            &:nth-child(5){
                .badge{
                    top: calc(8px + .425rem);
                }
                width: 75%;
                height: 175px;
                float: left;
                position: relative;
                // padding-right: .425rem;
                // padding-top: .425rem
            }
        }
    }
}



@media only #{$media} and ($feature_min : $value_five) {

    .home-background{
        margin-top: -2em !important;
    }

    .row.product-info{
            width: 100% !important;
        }
    

    .faq-item{
        min-width:50%;
        max-width:50%
    }

    .results-list{
        margin-right: 3px;
        overflow-y: auto;
        // width: 100%;
        &.bar{
            max-height: 80vh;
        }
        &.home{
            max-height: 500px;
        }
    }

    .hide-on-top{
        top:-100px !important
    }

    .slider-wr-grid{
        // &.featured{
        //     display: inline-flex;
        //     grid-template-columns: 500px auto;
        //     grid-gap: 1em;
        //     width: 100%;
        // }
    }

    .products-details-tab{
        .tabs {
            li {
                &:hover{
                    &::after{
                        width: 100%;
                        content: '';
                        right: 0;
                        height: 2px;
                        bottom: 0px;
                        background-color: $border-color;
                        position: absolute;
                        z-index: 5;
                    }
                }
            }
        }
    }

    .hoverable{
        &:hover{
            background-color: $background-color
        }
    }

    .strain-features{
        grid-template-columns: repeat(3, minmax(50px, 1fr));
    }

    // .swiper-container{
    //     padding-left: 35px !important;
    //     padding-right: 35px !important;
    // }

    .filter-main-items{
        .hoverable{
            &:hover{
                background-color: rgba($main-color, 0.05);
            }
        }
    }

    .aside-filter-fixed{
        overflow-x: hidden;
        position: sticky;
        top: 0px;
        // height: calc(100vh - 90px);
    }

    .pt-10-d{
        padding-top: 7rem;
    }

    .pb-5d{
        padding-bottom: 4rem;
    }

    .side-marker{
        position: relative;
        &.active{
            background-color: lighten($main-color , 60%);
            &.backgrounded{
                background-color: $white-color !important;
                div{
                    ul{
                        li{
                            &.active{
                                background-color: $background-color;
                            }
                        }
                    }
                }
            }
            &:before{
                position: absolute;
                height: 100%;
                background-color: $main-color;
                width: 5px;
                content: '';
                left: 0;
            }
        }
        &.light-background{
            background-color: white !important;
        }
    }
    
    .dictionary > li > ul > li{
      
        margin-left: 1rem !important;
    }

    .right-bordered-desktop{
        border-right: 1px solid #6c34781c;
    }

    section.dictionary{
        padding-left: 2rem;
        padding-right: 15px !important;
    }

    .global-search-cont.active{
        max-width: 700px !important;}

    aside > div{
        padding-top: 10px;
    }

    .embla__viewport {
   
        // overflow-y: hidden;
        // overflow-x: visible;
        width: 100%;
    }

    .map-layout{
        right: 0 !important;
    }

    .cards-section-title{
        font-size: 1.585rem !important;
        font-weight: 700 !important;
    }

    .w-500px{
        width: 500px;
    }

    
    .page-title{
        font-size: 28px !important;
        font-weight: 900;
    }

    // .carrousel-title{
    //     padding-left: 10px;
    //     padding-right: 10px;
    // }
    
    .sidebar-page{
        display: flex;
        flex-direction: row;

    }
    .tab-on-mobile{
        padding-left: 0 !important;
        padding-right: 0 !important;
        
    }
    .aside-filter.dictionary{
        ul{
           
            padding-top: 0em;
            
    
          
                li > span{
                    padding-left: 15px !important;
                    padding-top: 0.4rem;
                    padding-bottom: 0.4rem;
                }
                li > a{
                    padding-left: 15px !important;
                    padding-top: 0.4rem;
                    padding-bottom: 0.4rem;
                }
            
            
        }
    }
    .filter-main-items {

           
          
                li > span{
                    // padding-left: 0px !important;
                    padding-top: 0rem;
                    padding-bottom: 0rem;
                }
                
            
            
        
    }

    .grid-categories-product-page{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 2em
    }
                    
              
    

    // .logo-cont{
    //     background-color: #572b60 !important;
    // }
    
    .grid-two-columns{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 1rem 1rem;
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .grid-three-columns{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1rem 1rem;
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }
    

    .reward-timeline{

        .rc-steps{
            display: inline-block !important;
            .rc-steps-item{
                .rc-steps-item-container{
                    cursor: pointer;
                    &:hover{
                        background-color: $background-color;
                        border-radius: 6px;
                    }
                }
                
                width: 25% !important;
                margin-bottom: .5rem
            }
        }
    }

    .cards-grid-list-wide{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
    }

    .show-mobile{
        display: none !important;
    }

    .deals-result-grid{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 1em;
        li{
            padding: 0.6rem 0.45rem;
            // background-color: whitesmoke;
            border: 1px solid rgb(216, 216, 216);
            border-radius: 6px;
            display: flex;
            font-size: 18px;
            align-items: center;
            // justify-content: center;
            cursor: pointer;
            &:hover{
                background-color: darken(whitesmoke, 1%);
            }
        }
    }

    .deals-result-grid-2{
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
        grid-gap: 1em;
        li{
            padding: 0.6rem 0.45rem;
            // background-color: whitesmoke;
            border: 1px solid rgb(216, 216, 216);
            border-radius: 6px;
            display: flex;
            font-size: 18px;
            align-items: center;
            // justify-content: center;
            cursor: pointer;
            &:hover{
                background-color: darken(whitesmoke, 1%);
            }
        }
    }

    .show-til-991{
        display: none !important;
    }
    .results-container{
        // transform: translateY(3px);
        // opacity: 1;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 20%);
        // &.grid{
        //     display: grid;
        //     grid-template-columns: minmax(270px, 600px) 210px;
        // }
        // grid-gap: 1em;
        z-index: 50;
    }
    
    .navbar {
        // flex-direction: column !important;
        .navbar-darken{
            background-color: darken($main-color, 5%);
        }
        .location-handler{
            padding-right: 1rem;
            // border-right: 1px solid $main-color;
            display: flex;
            align-items: center;
            display: flex;
            justify-content: flex-start;
            i{
                font-size: 16px;
                margin-right: 6px;
                transform: translateY(-1px);
            }
            button {
                color: white;
            }
        }
        .navbar-bottom-level{
        
            display: grid;
            grid-template-columns: 275px auto;
            grid-gap: 1em;
            
            // justify-content: space-between;
            width: 100%;
            
            
        }
        .navbar-top-level{
            display: grid;
            grid-template-columns: minmax(150px, 295px) minmax(500px, 600px) 295px;
            grid-gap: 1em;
            padding-top: 10px;
            padding-bottom: 10px;
            justify-content: space-between;
            width: 100%;

            
        }
    }
    

    .clients-grid{
        display: grid;
        grid-template-columns: repeat(5, minmax(0px, 1fr));
        grid-gap: 1.5em;
    }

    .business-landing .cards-wrapper{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 3rem 1rem;
    
    }
    


    .filters-mobile-btn{
        display: none !important;
    }


    .sidebar-pane{
        .sidebar-filter{
            position: sticky !important;
            top:100px
        }
    }
    
    
    .product-details-area{
        grid-template-columns: repeat(20, minmax(0px, 1fr));
        .grid-product{
            grid-column: 1 / 14;
        }
        .grid-retailers{
            grid-column: 14 / 21;
        }
    }
    .cards-grid-list{
        display: grid;
        grid-template-columns: repeat(5, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    

    .cards-grid-list-with-sidebar{
        display: grid;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
        .featured{
            grid-column: span 2; 
        }
    }
    .cards-grid-list-with-sidebar-small{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    
    .cards-grid-list-small{
        display: grid;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .grid-card-sidebar{
        display: grid;
        grid-template-columns: 270px auto;
        grid-gap: 1em;
    }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {
    .cards-grid-list{
        display: grid;
        grid-template-columns: repeat(4, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .cards-grid-list-with-sidebar{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
        .featured{
            grid-column: span 2; 
        }
    }
    .cards-grid-list-with-sidebar-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .cards-grid-list-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
}

@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {

    
    .cards-grid-list{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .cards-grid-list-with-sidebar{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
        .featured{
            grid-column: span 2; 
        }
    }
    .cards-grid-list-with-sidebar-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
}

@media only #{$media} and ($feature_max : $value_one) {
    .meta-articles{
        display: flex;
        flex-direction: column;
        .separator{
            display: none;
        }
    }

    .mySwipera{
        .content{
            padding: 10px !important;
        }
        h2{
            font-size: 20px !important;
        }
        h4{
            font-size: 12px !important;
        }
        i{
            font-size: 12px !important;
        }
        .banner-logo{
            width: 80px !important;
            height: 80px !important;
        }
    }

    .brand-name {
        font-size: 20px !important;
        font-weight: 900;
    }
    .cards-grid-list{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
}



@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_eight) {
    .cards-container{
        // .container{
        //     padding-left: 250px;
        // }
    }
}

@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_seven) {
    .retailers-sidebar-wrapper{
        // width: 300px;
    }
    
}
@media only #{$media} and ($feature_min : $value_seven) {
    .sb-desk{
        max-width: 350px !important;
    }
    
    .fullwidth-filter-sidebar-static{
        max-width: 350px !important;

        &.small{
            width: 300px !important;
        min-width: 300px !important;
        }
    }

    .retailers-sidebar-wrapper{
        // width: 35%;
        display: flex;
        align-items: flex-start;
        .retailers-sidebar-container{
            min-width: 450px;
        }
    }
    .main-banner {
        background: {
            size: cover;
            position: top right;
        }
    }
}

@media only #{$media} and ($feature_min : $value_seven) {
    .retailers-sidebar-wrapper{
        // width: 35%;
        display: flex;
        align-items: flex-start;
        .retailers-sidebar-container{
            min-width: 450px;
        }
    }
}
@media only #{$media} and ($feature_min : $value_five) {

    .deal-modal-logo{
        width: 70px;
        height: 70px;
        border-radius: 5px
    }

    .big-badges-desktop{
        button{
            font-size: 16px !important;
        }
    }

    .dispensary-top-info{
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        img{
            align-self: center;
        }
        span{
            // padding-left: 1rem;
            // text-align: center;
        }
        small{
            // text-align: center;
            // padding-left: 1rem;
        }
    }
    .hide-desktop{
        display: none !important;
    }
    .navbar-nav{
        .nav-link{
            svg{
                width: 14px;
                height: 14px;
                margin-right:5px;
                padding-bottom: 1px
            }
        }
       
    }
}

@media only #{$media} and ($feature_min : $value_three) {
    
    .navbar-nav{
        .nav-item{
            .nav-link{
                justify-content: flex-start !important;
            }
        }
    }
    .container-fluid{
        width: 100%;
        max-width: 122.5rem !important;
        padding-right: 30px !important;
        padding-left: 30px !important;
        margin-right: auto;
        margin-left: auto;
    }


    .ticket-card{
        width: 500px !important;
        max-width: 500px !important;
    }

    .main-banner{
        // height: 500px;
    }
    
    .dispensaries-side-bar{
        .retailer-card-item{
            // margin-left: 30px;
            // margin-right: 22px !important;
        }
    }
   
    .map-icons-overlay{
        transform: translateX(calc(-100% - 31px))
    }
    .product-static-width{
        max-width: 285px !important;
        width: 285px !important;
        min-width: 285px !important;
    }

    .retailers-sidebar-wrapper.left-padding{
        padding-left: 15px;
        margin-left: 15px;
        border-left: 1px solid #f0f0f0;
    }

    .dispensary-pane{
        position: relative;
        min-width: 405px;
        max-width: 405px;
    }

    .hide-big{
        display: none !important;
    }
}

@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {

    .clients-grid{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        grid-gap: 1.5em;
    }

    .business-landing .cards-wrapper{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 3rem 1rem;
    
    }

    .navbar-nav{
        .nav-link{
            flex-direction: column !important;
            svg{
                width: 16px;
                height: 16px;
            }
        }
        a{
            font-size: 10px !important;
        }
    }
    .location-button{
        // span{
        //     display: none !important;
        // }
    }
}

@media only #{$media} and ($feature_max : $value_four) {

    

    .products-info{
        display: flex;
        position: relative;
       
    }

    .section-title .sub-title{
        display: none
    }

    .product-image{
        display: flex;
        justify-content: center;
    }

    

    .faq-item{
        min-width:100%;
        max-width:100%
    }


    .mobile-justify-center{
        justify-content: center;
    }

        .no-search-open{
            &.hide-on-top{
            top:-100px !important;
            position: fixed !important;
            }
        }
        .search-open{
            top:0px !important;
            position: fixed !important;
        }
    

    .global-search-cont{
        margin-top: 0px

    }

    .mobile-search-input-result{
        position: fixed;
        top:50px;
        background-color: white;
        // height: 100%;
        .bar{
            max-height: calc(100vh - 110px) !important;
            overflow-y: auto ;
        }
    }

    .show-cancel::-webkit-search-cancel-button{
        display: block !important;
    }
    .global-search-input{
        padding-left: 30px !important;
        padding-right: 10px !important;
    }
    .header-overlay{
        width: 100vw;
        height: 100vh;
        position: fixed;
        background-color: rgba(0, 0, 0, .5);
        z-index: 998;
        top: 0;
        left: 0;
    }

    // .carrousel-wrapper{
    //     .swiper-slide{
    //         width: 178px !important;
    //     }
    // }

    // .swiper-slide{
        
    //     max-width: 100vw !important;
    //     &.featured {
    //         width: 320px !important;
    //         max-width: 100% !important;
    //     }
    // }
    .mySwipera{
        &.swiper-container{
            padding-right: 0px !important;
            overflow: hidden !important;
        }
        
        .swiper-slide{
            width: 100% !important;
        }
    }

    .col-featured{
        display: none !important;
    }

    .products-page-gallery{
        width: 60% !important;
        margin: 0 auto;
        margin-bottom: 60px;
    }

  


    .modal-overlay .modal-wrapper.top-close {
        padding-top: 4rem;}

    .swal2-modal{
        
    }

    .deal-modal-logo{
        width: 50px;
        height: 50px;
        border-radius: 5px
    }

    .visible-scroll::-webkit-scrollbar {
        -webkit-appearance: none;
    }
    
    .visible-scroll::-webkit-scrollbar:vertical {
        width: 9px;
    }
    
    .visible-scroll::-webkit-scrollbar:horizontal {
        height: 9px;
    }
    
    .visible-scroll::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, .3);
        border-radius: 10px;
        border: 2px solid #ffffff;
    }
    
    .visible-scroll::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #ffffff;
    }

    .horizontal-scroll-mobile{

        max-width: 100vw;
        overflow-x: auto;
    }

    .cookie-modal{
        z-index: 10;
        bottom: 50px !important;
    }

    .big-badges-desktop{
        button{
            font-size: 16px !important;
        }
        overflow-x: auto;
    display: flex;
    }

    .grid-filters{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        li { 
            border-right: 1px solid #6c34781c !important;
            &:last-child{
                border-bottom: 1px solid #6c34781c !important
            }
        }
    }

    .h-100-mobile{
        height: 100vh !important;
    }

    .global-search-cont{
        &.no-borders{
            .results-container{
                border-right: none;
                border-left: none;
                border-bottom: none;
                box-shadow: none !important;
            }
            .results-container .results-list{
                margin-right: 0px;
                .search-cont-title{
                    padding-left: 0;
                    padding-right: 0;
                    padding-top: 0;
                }
                .collection-results-container{
                    padding: 0;
                }
            }
        }
    }


    .global-search-cont.active{
    
        transition: none ;
        
        .global-search-input{
            width: 450px;
            padding-right: 50px;
            max-width: 100%;
            background-color: white;
            border: 1px solid darken($white-color, 10%) !important;
        }
        .search-dropdown{
            width: 100px;
        }

        .results-container {
            transform: translateY(50px);
                transition: all .3s ease-in-out;
                opacity: 0;
           &.active{
                transition: all .3s ease-in-out;
               transform: translateY(5px);
               opacity: 1,
           }
   
        }
       
        .search-btn {
            color: $main-color;
            font-size: 18px;
            width: 48px;
            border-bottom-right-radius: 6px !important;
            border-top-right-radius: 6px !important;
        }
    }

   
    .nav-search-input-wrapper{
        padding-left: 1rem;
        padding-right: 1rem;
        height: 45px !important;
        // transition: all 0.3s ease-in-out;
        overflow: hidden;
        position: relative;
        
     
        i{
            font-size: 20px !important;
            color: #686868;
            position: absolute;
            top: calc(50% - .25rem);
            transform: translateY(-50%) translateX(7px);
           opacity: 1;
            
        }
        &.not-show{
            height: 0px !important;
            // transition: all 0.3s ease-in-out;
            .search-mobile-input{
                // transform: translateY(-20px);
                opacity: 0;
               
            }
            i{
                opacity: 0;
                
            }
        }
        .search-mobile-input{
            height: 38px !important;
            border-radius: 30px !important;
            margin-bottom: .5rem;
            padding-left: 2rem;
            // transform: translateY(0px);opacity: 1;
        }
    }
    

    
    .strain-features{
        grid-template-columns: repeat(1, minmax(50px, 1fr));
    }

    .no-hover-mobile {
        &:hover{
            background-color: none !important;
        }


    }

    .swiper-button-next{
        display: none !important;
    }

    .swiper-container{
        overflow: visible !important;
    }

    .no-overflow-hidden{
        .swiper-container{
            overflow: hidden !important;
        }
    }

    .swiper-button-prev{
        display: none !important;
    }

    .swiper-container{
        padding-left: auto !important;
        padding-right: 15px !important;
        padding-top: 1px;
    padding-bottom: 1px;
    }

    .deals-result-grid{
      
        li{
            border-bottom: 1px solid #cccccc;
            padding-top: .5rem;
            padding-bottom: .5rem;
            &:first-child{
                border-top: 1px solid #cccccc;
            }
        }
    }

    .deals-result-grid-2{
       
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
        li{
            padding: 0.3rem 0.45rem;
            // background-color: whitesmoke;
            &:not(:last-child){
                border-bottom: 1px solid rgb(216, 216, 216);
            }
            
            display: flex;
            font-size: 14px;
            align-items: center;
            // justify-content: center;
            cursor: pointer;
            &:hover{
                background-color: darken(whitesmoke, 1%);
            }
        }
    }

    input[type="color"],
    input[type="date"],
    input[type="datetime"],
    input[type="datetime-local"],
    input[type="email"],
    input[type="month"],
    input[type="number"],
    input[type="password"],
    input[type="search"],
    input[type="tel"],
    input[type="text"],
    input[type="time"],
    input[type="url"],
    input[type="week"],
    select:focus,
    textarea {
        -webkit-appearance: none;
        font-size: 16px;
    }

    .mobile-view{
        border-bottom: 1px solid #8b8b8b;
        background-color: rgba(0, 0, 0, 0.1)
    }

    select,
    textarea,
    input {
        font-size: 16px !important;
    }

    .mobile-location-t{
        position: absolute;
        transform: translateY(-14px);
    }
    .cart-button{
        .badge {
            margin-top: -10px;
            width: 20px;
            height: 20px;
            font-size: 12px !important;
        }
    }
    .navbar {
        &.mobile-v{
            padding: 0 !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
            padding-top: 15px !important;
            padding-bottom: 10px !important;
        }
    }

    .flex-column-mobile{
        flex-direction: column !important;
    }

    .badge-amenity{
        font-size: 0.715rem !important;
        padding: 0.3rem 0.65rem;
    }

    .location-button{
        // padding-bottom: .5rem;
        i{
            font-size: 14px !important; 
        }
    }
    .deliver-info{
        font-size: 12px !important;
    }

    .modal-content{
        &.full-width{
            width: 100vw !important;
            max-width: 100vw !important;
        }
    }

    .modal-wrapper{
        &.full-width{
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
            align-items: flex-end !important;

        }
    }

    section.dictionary{
        margin-left: 15px;
        margin-right: 15px;
    }

    .global-search-cont.active{
        width: 100% !important;
    }

    .bottom-side-mobile{
        overflow-x: hidden;
        position: fixed;
        bottom: 0;
        z-index: 3;
        width: 100%;
        border-top: 2px solid #eaeaea;
        .side-marker{
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .filter-main-items{
        .side-marker{
            &:first-child{
                margin-left: 8px !important;
            }
            &:last-child{
                margin-right: 8px !important;
            }
        }
    }
    

    .embla__viewport {
   
        // overflow-y: hidden;
        // overflow-x: scroll;
        width: 100%;
    }


    .embla{
        margin-left: -15px !important;
        margin-right: -15px !important;
    }
    .embla__button {
        // display: none !important;
    }
    .side-marker{
        a{
            position: relative;
        }
        // &.active{
        //     a{
        //         &:after{
        //             content: '';
        //             width: 100%;
        //             height: 2px;
        //             background-color: #6c3478; 
        //             position: absolute;
        //             bottom: -8px;
        //             left: 0;
        //         }
        //     }
        // }
    }
    .map-layout{
        width: 100% !important;
    }

    .brand-image-wrapper{
        text-align: center;
    }

    .align-items-center-mobile{
        align-items: center;
    }


    .dispensary-top-info{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span{
            text-align: center;
        }
        small{
            text-align: center;
        }
    }

    .page-title{
        font-size: 22px !important;
        font-weight: 900;
    }

    .sidebar-page{
        display: flex;
        flex-direction: column;
    }
    .tab-on-mobile{
        padding-left: 0 !important;
        padding-right: 0 !important;
        ul{
            // margin-left: 15px !important;
            // margin-right: 15px !important;
            display: flex;
            overflow-x: auto;
            overflow-y: clip;
            li{
                position: relative;
                white-space: nowrap !important;
                margin-right: 0px !important;
                margin-left: 0px !important;
                &.active{
                  
                    background-color: rgba($main-color, 0.2) !important;
                    span{
                        font-weight: 900 !important;
                    }
                    &:before{
                        position: absolute !important;
                        bottom: 0 !important;
                        width: 100% !important;
                        left: 0 !important;
                        height: 2px !important;
                        background-color: #6c3478 !important;
                    }
                }
                .icon{
                    display: none !important;
                }
            }
        }
        
    }

    .filter-button button{
        border-radius: 0 !important;
    }

    .dispensary-hoa-text{
        p{
            font-size: 14px !important;
        }
    }

    .dispensary-page-nav{
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid whitesmoke;
    }

    .tab.sticky.shadowed{
        box-shadow: 0px 11px 12px 0px rgb(0 0 0 / 10%);
    }
    
    .grid-categories-product-page{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 2em
    }
    .mapboxgl-ctrl-logo{
        margin-top: -60px !important;
    }
    .mapboxgl-ctrl-attrib{
        margin-top: -70px !important;}
    .search-dropdown{
        width: 50px !important;
    }
    .global-search-cont.active form.grid {
        display: grid;
        grid-template-columns: auto 48px !important;
        background-color: #ffffff;
        position: relative;
    }

    .search-nav-cont{
        max-width: 100%;
    flex: 0 0 100% !important;
    }

    .main-carrousel .carrousel-button.next {
        transform: translateX(-10px) translateY(-50%) !important;
        right: -20px !important;
    }
    .main-carrousel .carrousel-button.prev {
        transform: translateX(-10px) translateY(-50%) !important;
        right: 20px !important;
    }
    .main-carrousel{
        .location-handler{
            display: none !important;
        }
    }

    // .main-banner {
    //     height: 100vh !important;
    // }
    
    .fullwidth-filter-sidebar-static{
        overflow-x: hidden;
    }
    .grid-two-columns{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        grid-gap: 1rem 1rem;
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .cards-grid-list-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .grid-three-columns{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 1rem 1rem;
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .reward-timeline{
        
        .rc-steps{
            // display: inline-block !important;
            overflow-x: scroll;
            
            .rc-steps-item{
                .rc-steps-item-container{
                    cursor: pointer;
                    &:hover{
                        background-color: $background-color;
                        border-radius: 6px;
                    }
                }
                
                // width: 25% !important;
                margin-bottom: .5rem
            }
        }
    }

    .cards-grid-list-wide{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        justify-items: center;
        .balance-card{
            width: 260px;
            height: 150px;
        }
    }

    .hide-mobile{
        display: none !important;
    }
    .show-mobile{
        display: block;
    }
    

    .resizable-drawer{
        height: calc( 100vh - 90px );
        background-color: white;
        position: fixed;
        width: 100vw;
        // top: 100px;
        z-index: 1000;
        border-top: 1px solid $border-color;
        // transform: translateY(calc( 100vh - 120px ));
        // &.active{
        //     transform: translateY(0px);
        // }
        // transition: all 0.5s ease-in-out;
        .drawer-trigger{
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 5px 10px 1px rgb(0 0 0 / 20%);
            // position: absolute;
            z-index: 2;
            background-color: white;
            .drawer-trigger-icon{
                width: 45px;
                height: 5px;
                border-radius: 20px;
                background-color: rgb(255, 210, 253);
            }
        }
    }


    .tabs{
        display: flex;
        overflow-x: auto;
    }
    .results-container{
        position: relative !important;
        transform: translateY(10px) !important;
        opacity: 1;
        box-shadow: 1px 1px 5px 0px rgb(0 0 0 / 20%);
        &.grid{
            display: grid;
            grid-template-columns: 100% 0px;
            // grid-gap: 1em;
        }
        margin-bottom: 1rem;
        .results-list{
            width: 100%;
            height: 100%;
        }
    }
    .navbar{
        .nav-link{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.5rem .5rem;
        }
    }

    .global-search-cont{
        display: none !important;

        &.opened{
            display: flex !important;
            flex-direction: column;
        }
        
    }

    .show-til-991{
        display: flex !important;
    }
    
    .navbar-area .main-nav .navbar .navbar-nav .nav-item a{
        align-items: center !important;
        justify-content: flex-start !important;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
    }

    .navbar {
        
        .navbar-top-level{
            justify-content: space-between;
            display: flex;
            padding-top: 10px;
            padding-bottom: 10px;
            width: 100%;

            .search-form{
                display: grid;
                grid-template-columns: auto 45px;
                overflow: hidden;
                background-color: #ffffff;
                position: relative;

                
            }
        }
        .others-option{
            display: none !important;
        }
        .location-handler{
            width: 100%;
            border-right: none !important;
            padding-right: 0rem !important;
        }
        .navbar-bottom-level {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            grid-gap: .5rem !important;
            // padding-top: .5rem !important;
            
            
        }
        .btn-mat-purple{
            display: none !important;
        }
    }

    .main-nav, .enterprise-nav {

        height: 100% !important;

        .mobile-v{
            height: 100%;
        }
        .global-search-cont.opened{
            height: 100% !important;
        }
        
        .navbar {
            hr{
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
            .navbar-collapse{
                &:not(.show){
                    display: none !important;
                }
                &.show{
                    .main-menu-separator{
                        display: block;
                    }
                    // padding-top: 10px !important;
                    width: 100% !important;
                    display: flex !important;
                    flex-direction: column-reverse !important;
                }
            }
            position: relative;
            .navbar-nav {
                padding-top: 10px;
                overflow-y: auto;
                .nav-item {
                    width: 100%;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    .dropdown-menu {
                        top: 40px;
                    }

                    &:first-child {
                        a {
                            margin-left: 15px;
                        }
                    }
                    a {
                        font-size: 16px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        margin-right: 0 !important;
                        margin-left: 0 !important;
                        justify-content: flex-start !important;

                    }

                    &.megamenu {
                        position: relative;
                        .container {
                            .col {
                                width: 100%;
                                flex-basis: auto;
                                margin-bottom: 20px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .navbar-expand-md .navbar-toggler {
        display: block;
        padding-right: 0;
    }

    

    // .global-search-cont{
    //     display: none;
    //     &.active{
    //         display: flex !important;
    //     }
    // }

    .business-landing{  
        .header-subtitle{
            text-align: center !important;
        }
       .left-detail-section .image-wrapper {
            align-items: center !important;
        }
    }

    .pricing-wrapper {
        justify-content: center;
        &:not(:last-child) {
            margin-bottom: 2rem
        }
        .pricing-card{
            max-width: 450px;
            margin: 0 auto;
        }
    }

    .sidebar-pane{
        background-color: white;
        top: 0;
        left: 0;
        // background-color: white;
        width: 100vw;
        position: absolute;
        min-height: calc(100vh);
        max-height: calc(100vh);
        padding-left: 0px !important;
        padding-right: 0px !important;
        position: fixed;
        z-index: 100;
        padding-top: 100px;
        padding-bottom: 50px;

        .sidebar-filter{
            max-height: calc(100vh - 150px);
            padding-left: 15px !important;
            padding-right: 15px !important;
            // width: 100vw;
            // padding-left: 15px;
        }
        .filter-search{
            width: 100%;
        }
        .filter-button{
            position: fixed;
            bottom: 0;
            left: 0;
            .button{
                height: 50px;
            max-height: 50px;
            }
        }
    }

    .cards-grid-list-with-sidebar{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
        .featured{
            grid-column: span 2; 
        }
    }
    .cards-grid-list-with-sidebar-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }
    .product-details-area{
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        .grid-product{
            grid-column: 1;
        }
        .grid-retailers{
            grid-column: 1;
        }
    }
    .product-description{
        white-space: break-spaces;
        padding-bottom: 2em;
    }
    .retailers-sidebar-wrapper{
        // border-top: 1px solid whitesmoke;
        padding-top: 2em;
        width: 100%;
    }
    .product-all-info{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    
    .product-details-area{
        background-color: $white-color;
        .badge{
            font-size: 18px;
            margin-bottom: 1rem;
        }
    
        // .product-info{
        //     width: 100%;
        // }
    
        .retailer-info{
            width: 100px;
        }
    }

    .hide-991{
        display: none !important;
    }

}

@media only #{$media} and ($feature_min : $value_three) {

    

    .product-info{
        // border-right: 1px solid #6c34781c;
        padding-right: .35rem;
        margin-right: .35rem;
    }
    .pl-15{
        padding-left: 15px;
    }
    .pr-15{
        padding-right: 15px;
    }
    
    .global-search-cont.active{
        width: 100%;
        // max-width: 590px;
        width: 100%;
        transition: all 1.5s ease-out;

        // position: absolute;
        // left: 50%;
        // transform: translatex(-50%);


        .global-search-input{
            width: 100%;
            // max-width: 330px;
        }
        .search-dropdown{
            width: 100%;
        }
    }
    .global-search-cont{
        width: 200px;
        min-width: 116px;
    }
    .main-menu-separator{
        display: none !important;
    }
    .hide-text-big{
        font-size: 0 !important;
        cursor: pointer;
        i{
            
        }
    }
    

}

/* Max width 767px */
@media only #{$media} and ($feature_max : $value_two) {

    .home-deals-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap: 1em;
    }

    .cards-grid-list-extended{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .near-stores-card{
        flex-direction: column;
        align-items: flex-start !important;
        
        .left-side{
            width: 100%;
            justify-content: space-between;
            border-top: 1px solid #ebebeb;
            margin-top: .5rem;
            padding-top: .5rem;
        }
    }

    .brand-grid{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        grid-gap:1rem 1rem;
        gap: 1rem 1rem;
        margin-bottom: 1rem
}


    .dispensary-logo-wrapper{
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            width: 180px !important;
            height: 180px !important;
        }
    }
    
    .carrousel-button{
        display: none !important;
    }

    .main-banner-img{
        margin-top: -2.5rem
    }

    .clients-grid{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        grid-gap: 1.5em;
    }

    .business-landing .cards-wrapper{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        gap: 3rem 1rem;
    }

    

    .static-banner-content{
        .text-light{
            h1{
                font-size:26px;
                font-weight: 700;
            }
            h2{
                font-size:22px;
            }
            p{

                font-size:12px;
                font-weight:700;
            }
        }
        .text-dark{
            h1{
                font-size:26px;
                font-weight: 700;
            }
            h2{
                font-size:22px;
            }
            p{
           
                font-size:12px;
                font-weight:700;
            }
        }
    }
    

    .container-screenwide{
        width: 100%;
        height: calc(100vh - 90px);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right top;
        display: flex;
        align-items: center;
        justify-content: center;
        .big-search{
            position: relative;
            .bx-search{
                position: absolute;
                font-size: 1.5rem;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
                color:$optional-color;
            }
            input{
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
                padding-left: 40px !important;
                border: 1px solid $main-color
            }
            button{
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
        section{
            background-color: unset !important;
        }
        .section-title{
            border-radius: 6px;
            padding: 2rem 4em;
            background-color: rgb(0 0 0 / 40%);
            border: unset !important;
        }
        h1{
            color: white;
            font-size: 1.725rem;
            font-family: $font-family3;
            font-weight: 700;
        }
        .sub-title{
            color: white;
            margin-bottom: 2rem;
            font-size: 18px;
            font-family: $font-family3;
            font-weight: 400;
        }
    }

    .grid-categories{
        overflow: hidden;
        border-radius: 6px;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
        grid-auto-rows: 1fr;
        .badge{
            position: absolute;
            top: 8px;
            left: 8px;
        }
        .category{
            cursor: pointer;
            transition: all 0.175s ease-in-out;
            overflow: hidden;
            .badge{
                z-index: 5;
            }
            img{
                transition: all 0.5s;
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.07);
                    transition: all 0.5s;
                }
            }
            &:nth-child(1){
                position: relative;
                .badge{
                    top: calc(8px + .425rem);
                    left: calc(8px + .425rem);
                }
            }
            &:nth-child(2){
                position: relative;
                .badge{
                    left: calc(8px + .425rem);
                }
            }
            &:nth-child(4){
                position: relative;
                .badge{
                    left: calc(8px + .425rem);
                    top: calc(8px + .425rem);
                }
            }
            &:nth-child(3){
                position: relative;
                .badge{
                    left: calc(8px + .425rem);
                }
            }
            &:nth-child(5){
                position: relative;
                .badge{
                    top: calc(8px + .425rem);
                }
            }
        }
    }

    .container{
        padding-left: 15px !important;
        padding-right: 15px !important;
        width: 100vw !important;
        min-width: 100% !important;
        max-width: 100% !important;
    }

    .main-banner{
        h1{
            font-size: 1.595rem !important;
            font-weight: 700;
        }
        h2{
            font-size: 0.985rem !important;
            font-weight: 500;
            
        }
        h4{
            font-size: 0.875rem !important;
        }
    }

    .navbar-nav{
        .nav-item{
            .nav-link{
                justify-content: flex-start !important;
                span{
                    font-size: 14px !important;
                }
                .badge-absolute{
                    display: none;
                }
                svg{
                    width: 24px;
           
                    padding-bottom: 1px;
                    margin-right: 7px;
                    margin-left: 5px;
                }
                justify-content: flex-start !important;
            }
        }
    }
    
    .map-icons-overlay{
        transform: translateX(calc(0)) !important;
        right:1rem
    }
    
    .product-static-width{
        width: 50%;
    }

    .btn-toggle-dispensary-pane{
        display: none !important;
    }

    .carrousel-button {
        margin-right: 0px !important;
    }

    // .top-slider{
    //     align-items: center !important;
    // }

    .section-title h2 {
        margin-bottom: 0;
        font-size: 22px !important;
        font-weight: 700;
    }

    .section-title .sub-title{
        display: none
    }

    .order-buttons {
        margin-top: 1rem;
    }
    .middle-area{
        p{
            text-align: center;

        }
        
        .legal-links, .download-app, .social-link{
            margin-top: 1rem;
            display: flex;
            align-items: center;
            justify-content: center !important;
        }
    }

    .horizontal{
        justify-content: space-between !important;
        li{
            margin-left: 0 !important;
        }
    }

    .business-landing{

        .center-mobile{
            text-align: center;
        }
        .button-wrapper{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        padding-top: 5rem;
        padding-bottom: 5rem;
        img{
            max-width: 90vw;
        }
        h1{
            text-align: center;
        }
        // p{
        //     text-align: center;
        // }
 
        .buttons{
            margin-top: 1rem;
            margin-bottom: 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .btn{
                &:first-child{
                    margin-bottom: 1rem;
                }
            }
        }
        
        &.bottom-section{
            &.bg{
                background-image: url(../../../images/landing-bt-background.png);
                background-size: 1332px 393px;
                background-repeat: no-repeat;
                background-position: center;
            }
            padding-top: 6rem;
        }
    }

    .woocommerce-widget-top {
        &:before{
            display: none !important;
        }
    }
    .dispensary-pane{
        position: absolute;
        width: 100vw;
    }

    .products-card-container{
        width: 100% !important;
    }

   

    .d-none-mobile{
        display: none !important;
    }

    

    .global-search-cont{
        max-width: 9em !important;
        min-width: 5em !important;
        margin-right: auto;
    }
    .global-search-cont.active{
        min-width: 100% !important;
        margin-top: 0px;
        margin-bottom: 10px;
    }
    .navbar{
        .global-search-input{
            min-width: 100% !important;
        }
    }
    .global-search-input{
        width: 100% !important;
    }

    .d-none-mobile{
        display: none !important;
    }
    .main-nav, .enterprise-nav{
        min-height: 50px;
        width: 100vw;
        // box-shadow: 0px 5px 20px 1.5px rgb(0 0 0 / 20%);
        .results-container{
            position: relative !important;
        }
        .navbar-collapse{
            .others-option, .navbar-nav{
                margin-left: 0px !important;
                align-items: flex-start !important;
                .option-item{
                    width: 100%;
                    &:first-child{
                        margin-bottom: .5rem;
                    }
                }
                width: 100%;
                .nav-item {
                    &:after{
                        display: none;
                    }
                    .nav-link{
                        justify-content: flex-start !important;
                        &.location-button{
                            max-width: 100% !important;
                        }
                        i{
                            display: block !important;
                            font-size: 24px !important;
                            min-width: 35px;
                            padding-left: 5px;
                            padding-right: 5px;
                        }
                        span{
                            font-size: 18px;
                        }
                        justify-content: flex-start !important;
                        padding: 0 0px !important;
                    }
                }
                
            }
        }
    }

    .dispensary-pane{
        width: 100vw;
        z-index: 21;
    }

    body {
        font-size: 13.5px;
    }
    p {
        font-size: 13.5px;
    }
    .ptb-100 {
        padding: {
            top: 60px;
            bottom: 60px;
        };
    }
    .pt-100 {
        padding-top: 60px;
    }
    .pb-100 {
        padding-bottom: 60px;
    }
    .ptb-70 {
        padding: {
            top: 30px;
            bottom: 30px;
        };
    }
    .pt-70 {
        padding-top: 30px;
    }
    .pb-70 {
        padding-bottom: 30px;
    }
    .default-btn {
        padding: 10px 24px;
        font-size: 13px;
    }
    .optional-btn {
        padding: 10px 24px;
        font-size: 13px;
    }
    .section-title {
        margin-bottom: 35px;

        h2 {
            font-size: 28px;
        }
        &.text-left {
            margin-bottom: 15px;

            .default-btn {
                display: none;
            }
        }
    }
    .form-controla {
        height: 45px;
        padding: 0 0 0 12px;
        font-size: 14px;
    }
    textarea.form-controla {
        padding-top: 12px;
    }

    .top-header {
        text-align: center;
        padding: {
            top: 15px;
            bottom: 15px;
        };
    }
    .header-contact-info {
        li {
            font-size: 13.5px;
            margin: {
                left: 10px;
                right: 10px;
                bottom: 10px;
            };
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .top-header-discount-info {
        margin-top: 0;
        
        p {
            line-height: 1.8;
            font-size: 14px;
        }
    }
    .header-top-menu {
        text-align: center;

        li {
            font-size: 13.5px;
            margin: {
                left: 10px;
                right: 10px;
                top: 10px;
            };
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            a {
                padding-left: 20px;

                i {
                    font-size: 14px;
                }
            }
            &::before {
                right: -13px;
                top: 5px;
                height: 12px;
            }
        }
    }
    .header-top-others-option {
        display: block;
    }
    
    .navbar-area {
        // box-shadow: 0 1px 20px rgb(0 0 0 / 40%);
        position: relative;
        .others-option {
            // position: absolute;
            // top: 25px;
            // right: 100px;
            display: flex;
            flex-direction: column;
            .burger-menu {
                display: none;
            }
        } 
    }
    .main-banner {
        // height: 100vh;
        // padding: {
        //     top: 100px;
        //     bottom: 130px;
        // };
        &.banner-bg6 {
            background-position: top left;
        }
        &.banner-bg2 {
            background-position: top left;
        }
        // &.single-main-banner {
        //     padding: {
        //         top: 100px;
        //         bottom: 100px;
        //     };
        // }
    }
    .hero-banner {
        padding: {
            top: 100px;
            bottom: 0;
        };
    }
    .main-banner-content {
        .sub-title {
            margin-bottom: 12px;
            font-size: 16px;
        }
        h1 {
            margin-bottom: 12px;
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
        .btn-box {
            margin-top: 20px;

            .optional-btn {
                margin-left: 5px;
            }
        }
        &.text-center {
            .btn-box {
                .optional-btn {
                    margin-left: 2.5px;
                }
                .default-btn {
                    margin-right: 2.5px;
                }
            }
        }
    }
    .banner-content {
        padding: 20px;
        max-width: 100%;
        margin-left: 0;

        .line {
            border-width: 2px;
        }
        .sub-title {
            font-size: 17px;
        }
        h1 {
            margin: 10px 0 15px;
            font-size: 35px;
        }
        p {
            max-width: 100%;
            font-size: 16px;
            font-weight: 300;
        }
        .btn-box {
            margin-top: 20px;

            .default-btn {
                padding: 10px 18px;
                font-size: 12px;
            }
            .optional-btn {
                padding: 10px 18px;
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }
    .banner-image {
        margin-top: 30px;

        .circle {
            width: 290px;
            height: 290px;
        }
    }
    .home-slides {
        background-color: $white-color;
        
    }


    .single-categories-box {
        .content {
            padding: 15px 15px 0;
            text-align: right;

            h3 {
                font-size: 23px;
            }
        }
    }
    .categories-box {
        .content {
            top: 20px;
            left: 20px;
            padding: {
                top: 7px;
                bottom: 7px;
                left: 17px;
                right: 17px;
            };
            h3 {
                font-size: 16px;
            }
        }
    }

    .about-image {
        padding: {
            right: 0;
            bottom: 0;
        };
        img {
            width: 100%;

            &:nth-child(2) {
                position: relative;
                right: 0;
                margin-top: 20px;
            }
        }
    }
    .about-content {
        padding-left: 0;
        margin-top: 30px;

        h2 {
            font-size: 25px;
        }
        h6 {
            font-size: 16px;
        }
        .features-text {
            h5 {
                font-size: 16px;
                padding-top: 0;
            }
        }
    }
    .about-inner-area {
        margin-top: 0;
    }
    .about-text {
        margin-top: 30px;

        h3 {
            font-size: 18px;
        }
    }

    .single-testimonials-item {
        padding: 25px 20px 95px;

        p {
            font-size: 16px;
        }
    }

    .search-overlay {
        .search-overlay-form {
            max-width: 250px;
            width: 250px;
        }
    }

    .customer-service-content {
        h3 {
            padding-left: 25px;
            font-size: 18px;
        }
    }

    .login-content {
        text-align: center;

        h2 {
            margin-bottom: 20px;
            font-size: 22px;
        }
    }
    .new-customer-content {
        padding-left: 0;
        margin-top: 30px;
        text-align: center;

        h2 {
            margin-bottom: 13px;
            font-size: 22px;
        }
        span {
            font-size: 16px;
        }
    }

    .signup-content {
        max-width: 100%;

        h2 {
            font-size: 22px;
        }
    }

    .faq-accordion-tab {
        .tabs {
            margin-bottom: 20px;
            li {
                margin-bottom: 20px;
                padding: 15px;
                font-size: 14px;
                i {
                    font-size: 25px;
                }
            }
        }
    }
    .faq-accordion {
        .accordion {
            .accordion_title {
                font-size: 16px;

                i {
                    left: 15px;
                    top: 16px;
                }
            }
            .accordion-content {
                p {
                    font-size: 14.5px;
                    line-height: 1.8;
                }
            }
        }
    }

    .single-products-box {
        .products-image {
            .sale-tag {
                bottom: 65px;
            }
        }
        .products-content {
            h3 {
                font-size: 18px;
            }
            .price {
                font-size: 14px;
            }
        }
    }
    .products-box {
        .products-image {
            .sale-tag {
                bottom: 65px;
            }
        }
        .products-content {
            .category {
                font-size: 13px;
            }
            h3 {
                font-size: 18px;
            }
            .price {
                margin-top: 7px;
                font-size: 14px;
            }
        }
    }
    .single-productsBox {
        .products-content {
            .category {
                font-size: 13px;
            }
            h3 {
                font-size: 18px;
            }
            .price {
                margin-top: 7px;
                font-size: 14px;
            }
        }
    }
    .products-area {
        .woocommerce-widget-area {
            display: none;
        }
    }

    .error-content {
        h3 {
            font-size: 25px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 20px;
        }
    }

    .coming-soon-area {
        height: 100%;
        padding: {
            top: 100px;
            bottom: 100px;
            left: 15px;
            right: 15px;
        };
    }
    .coming-soon-content {
        margin-left: 0;
        padding: 30px 25px;

        h2 {
            margin-top: 25px;
            font-size: 27px;
        }
        #timer {
            margin-top: 10px;

            div {
                width: 95px;
                height: 100px;
                font-size: 35px;
                margin-top: 20px;

                span {
                    font-size: 14px;
                }
            }
        }
        form {
            max-width: 100%;
            margin-top: 40px;
        }
    }

    .offer-content {
        max-width: 100%;
        margin-left: 0;
        padding: 35px;

        &::before {
            margin: 5px;
        }
        h2 {
            font-size: 28px;
        }
        .default-btn {
            margin-top: 10px;
        }
    }
    .offer-content-box {
        max-width: 100%;
        margin-left: 0;
        text-align: center;

        span {
            font-size: 17px;
        }
        h2 {
            font-size: 30px;
        }
        p {
            font-size: 16px;
        }
        .default-btn {
            margin-top: 0;
        }
    }

    .track-order-content {
        max-width: 100%;
        padding: 30px;

        h2 {
            font-size: 22px;
        }
        form {
            padding: {
                left: 0;
                right: 0;
            };
        }
    }

    .products-compare-table {
        table {
            tr {
                td {
                    white-space: nowrap;
                }
            }
        }
    }

    .productsQuickView {
        overflow-y: auto !important;
        .modal-dialog {
            transform: translate(0,0px) !important;
        }
        .modal-dialog {
            max-width: 540px;
            margin: 0 auto;
        }
        .modal-content {
            padding: 35px 15px 15px;

            .products-content {
                margin-top: 30px;

                h3 {
                    font-size: 19px;
                }
                .products-info {
                    li {
                        font-size: 16px;
                    }
                }
                .products-color-switch {
                    h4 {
                        font-size: 16px;
                    }
                }
                .products-size-wrapper {
                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .sizeGuideModal {
        .modal-content {
            padding: 30px 20px;

            .modal-sizeguide {
                h3 {
                    margin-bottom: 5px;
                    font-size: 20px;
                }
            }
        }
    }
    .productsShippingModal {
        .modal-content {
            padding: 35px 20px;

            .shipping-content {
                h3 {
                    margin-bottom: 15px;
                    font-size: 20px;
                }
            }
        }
    }
    .productsFilterModal {
        &.modal {
            &.left {
                .modal-dialog {
                    width: 320px;
                }
            }
        }
    }

    .woocommerce-widget-area {
        .woocommerce-widget {
            .woocommerce-widget-title {
                font-size: 14px;
            }
        }
        .aside-trending-widget {
            .aside-trending-products {
                .category {
                    h3 {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .all-products-area {
        .container {
            .container {
                padding: {
                    left: 15px;
                    right: 15px;
                };
            }
        }
    }
   
    .products-collections-area {
        .container-fluid {
            width: 100%;
        }
    }

    .contact-info {
        h3 {
            margin-bottom: 13px;
            font-size: 19px;
        }
    }
    .contact-form {
        margin-top: 30px;

        h3 {
            margin-bottom: 13px;
            font-size: 19px;
        }
    }

    .single-gallery-item {
        .gallery-content {
            h3 {
                font-size: 18px;
            }
        }
    }

    .products-details-desc {
        margin-top: 30px;

        h3 {
            margin-bottom: 15px;
            font-size: 20px;
        }
        .price {
            font-size: 14px;
        }
        .products-info {
            li {
                font-size: 16px;
            }
        }
        .products-color-switch {
            span {
                font-size: 16px;
            }
        }
        .products-size-wrapper {
            span {
                font-size: 16px;
            }
            ul {
                li {
                    a {
                        font-size: 14px;
                    }
                }
            }
        }
        .products-info-btn {
            margin-bottom: 18px;

            a {
                font-size: 13px;
                margin-bottom: 7px;

                i {
                    font-size: 17px;
                }
            }
        }
        .products-add-to-cart {
            .input-counter {
                input {
                    height: 40px;
                    font-size: 16px;
                }
                span {
                    width: 40px;
                    line-height: 44px;
                    font-size: 20px;
                }
            }
            .default-btn {
                position: relative;
                top: -2px;
            }
        }
        .wishlist-compare-btn {
            .optional-btn {
                display: block;
                text-align: center;

                i {
                    top: 4px;
                }
                &:not(:first-child) {
                    margin: {
                        left: 0;
                        top: 15px;
                    };
                }
            }
        }
        .products-details-accordion {
            .accordion {
                .accordion-content {
                    .products-review-form {
                        .review-title {
                            .default-btn {
                                position: relative;
                                margin-top: 15px;
                            }
                        }
                        .review-comments {
                            margin-top: 25px;

                            .review-item {
                                padding-right: 0;

                                h3 {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .products-details-tab {
        .tabs {
            li {
                display: block;
                margin-right: 0;
                width: 100%;
                a {
                    padding: 13px auto;
                    font-size: 14px;

                    .dot {
                        left: 20px;
                    }
                }
            }
        }
        .tab-content {
            // padding: 20px;

            .tabs-item {
                .products-details-tab-content {
                    h3 {
                        margin-bottom: 12px;
                        font-size: 18px;
                    }
                    ul, ol {
                        li {
                            &:first-child {
                                margin-top: 10px;
                            }
                        }
                    }
                    .products-review-form {
                        .review-title {
                            .default-btn {
                                position: relative;
                                margin-top: 15px;
                            }
                        }
                        .review-comments {
                            margin-top: 25px;

                            .review-item {
                                padding-right: 0;

                                h3 {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .related-products {
        margin-top: 45px;

    }

    .cart-table {
        table {
            thead {
                tr {
                    th {
                        padding: 0 65px 15px 0;
                        font-size: 14px;
                    }
                }
            }
            tbody {
                tr {
                    td {
                        &.product-name {
                            a {
                                font-size: 16px;
                            }
                            ul {
                                margin-top: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .cart-buttons {
        .text-right {
            text-align: center !important;
            margin-top: 15px;
        }
    }
    .cart-totals {
        padding: 20px;
        max-width: 100%;
        margin: {
            left: 0;
            top: 30px;
            right: 0;
        };
        h3 {
            font-size: 19px;
        }
        ul {
            margin: 0 0 20px;

            li {
                &:last-child {
                    font-size: 17px;
                }
            }
        }
    }

    .sizing-guide-table {
        h3 {
            font-size: 18px;
        }
        .sizing-guide-table {
            table {
                thead {
                    th {
                        font-size: 16px;
                        padding-right: 30px;
                    }
                }
            }
        }
    }

    .user-actions {
        padding: 15px;

        span {
            font-size: 14px;
        }
        i {
            margin-right: 0;
            font-size: 17px;
            top: 2px;
        }
    }
    .billing-details {
        .title {
            font-size: 19px;
        }
        .form-group {
            .nice-select {
                font-size: 14px;
                line-height: 45px;
            }
        }
    }
    .order-details {
        margin-top: 30px;

        .title {
            font-size: 19px;
        }
        .order-table {
            table {
                thead {
                    tr {
                        th {
                            font-size: 16px;
                            white-space: nowrap;
                        }
                    }
                }
                tbody {
                    tr {
                        td {
                            font-size: 14px;
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .payment-box {
            padding: 25px;
        }
    }
    .order-details .payment-box .payment-method p [type="radio"]:checked + label::before, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::before {
        top: 3px;
    }
    .order-details .payment-box .payment-method p [type="radio"]:checked + label::after, .order-details .payment-box .payment-method p [type="radio"]:not(:checked) + label::after {
        top: 6px;
    }

   

    .page-title-area {
        padding: {
            // top: 40px;
            // bottom: 35px;
        };
    }
    .page-title-content {
        // text-align: center;

        h1 {
            font-size: 22px;
        }
        ul {
            // text-align: center;
            position: relative;
            top: 0;
            transform: unset;
            // margin-top: 10px;
            margin-left: 0;
            li {
                margin: {
                    left: 7px;
                    right: 7px;
                };
                &:first-child {
                    margin-left: 0;
                }
                &::before {
                    left: -8px;
                    // top: 5px;
                }
            }
        }
    }

    .products-filter-options {
        text-align: center;

        p {
            font-size: 16px;
            margin: {
                top: 12px;
                bottom: 8px;
            };
        }
        .products-ordering-list {
            max-width: 200px;
            margin: {
                left: auto;
                right: auto;
            };
            .nice-select {
                height: 30px;
                line-height: 30px;
                font-size: 16px;
            }
        }
    }
    #products-collections-filter {
        &.products-row-view {
            .products-col-item {
                .single-products-box {
                    display: block;
                    flex-wrap: unset;
                    align-items: unset !important;

                    .products-image {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    .products-content {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: {
                            left: 0;
                            right: 0;
                        };
                        .add-to-cart {
                            left: 0;
                        }
                        .star-rating {
                            right: 0;
                        }
                    }
                }
                .products-box {
                    display: block;
                    flex-wrap: unset;
                    align-items: unset !important;

                    .products-image {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    .products-content {
                        flex: 0 0 100%;
                        max-width: 100%;
                        padding: {
                            left: 0;
                            right: 0;
                        };
                        .add-to-cart {
                            left: 0;
                        }
                    }
                }
                .single-productsBox {
                    display: block;
                    flex-wrap: unset;
                    align-items: unset !important;

                    .products-image {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                    .products-content {
                        flex: 0 0 100%;
                        max-width: 100%;
                        text-align: center;
                        padding: {
                            left: 0;
                            right: 0;
                        };
                        .add-to-cart {
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
    }

    .single-offer-products {
        border-radius: 0;
        padding-right: 0;

        .image {
            border-radius: 0;

            a {
                border-radius: 0;

                img {
                    border-radius: 0;
                }
            }
        }
        .content {
            border: 1px solid #eeeeee;
            border-bottom: none;
            position: relative;
            right: 0;
            box-shadow: unset;
            border-radius: 0;
            padding: 25px;
            max-width: 100%;
            top: 0;
            transform: translateY(0);

            h3 {
                font-size: 22px;
            }
        }
    }

    .single-blog-post {
        .post-content {
            .category {
                font-size: 13px;
            }
            h3 {
                font-size: 18px;
                line-height: 1.3;
            }
            .details-btn {
                margin-top: 12px;
                font-size: 13.5px;
            }
        }
    }

    .instagram-title {
        margin-bottom: 30px;
    }

    .pagination-area {
        margin-top: 15px;
        
        .page-numbers {
            font-size: 17px;

            &.prev, &.next {
                top: 6px;
            }
        }
    }

    .widget-area {
        margin-top: 35px;

        .widget {
            .widget-title {
                font-size: 18px;
            }
        }
        .widget_posts_thumb {
            .item {
                .info {
                    .title {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .blog-details-desc {
        .article-content {
            .entry-meta {
                margin-bottom: -15px;

                ul {
                    li {
                        font-size: 14px;
                        margin: {
                            bottom: 15px;
                            right: 15px;
                        };
                        padding: {
                            right: 15px;
                            left: 0;
                        };
                        i {
                            display: none;
                        }
                        span {
                            font-size: 13px;
                        }
                    }
                }
            }
            h3 {
                font-size: 19px;
                margin-bottom: 13px;
                line-height: 1.5;
            }
        }
        .article-footer {
            padding-top: 25px;
            text-align: center;

            .article-tags {
                flex: 0 0 100%;
                max-width: 100%;
            }
            .article-share {
                flex: 0 0 100%;
                max-width: 100%;

                .social {
                    text-align: center;
                    margin-top: 15px;
                }
            }
        }
        .article-author {
            .author-profile {
                padding: 0 20px 20px;

                .author-profile-title {
                    .author-profile-title-details {
                        .author-profile-details {
                            h4 {
                                font-size: 16px;
                            }
                            span {
                                font-size: 13px;
                            }
                        }
                        a {
                            padding: 5px 10px 4px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    .comments-area {
        .comments-title {
            font-size: 19px;
        }
        .comment-author {
            .avatar {
                height: 50px;
                left: 0;
                position: relative;
                width: 50px;
                display: block;
                margin-bottom: 10px;
            }
        }
        .comment-body {
            padding-left: 0;
        }
        .comment-respond {
            .comment-reply-title {
                font-size: 19px;
            }
            .comment-form-author {
                width: 100%;
                padding-right: 0;
            }
            .comment-form-email {
                width: 100%;
                padding-left: 0;
            }
            .form-submit input {
                font-size: 14px;
                padding: 10px 30px 10px;
            }
            .comment-form-cookies-consent {
                label {
                    font-size: 16px;
                }
            }
        }
    }
    .comments-area .comment-respond input[type="date"], .comments-area .comment-respond input[type="time"], .comments-area .comment-respond input[type="datetime-local"], .comments-area .comment-respond input[type="week"], .comments-area .comment-respond input[type="month"], .comments-area .comment-respond input[type="text"], .comments-area .comment-respond input[type="email"], .comments-area .comment-respond input[type="url"], .comments-area .comment-respond input[type="password"], .comments-area .comment-respond input[type="search"], .comments-area .comment-respond input[type="tel"], .comments-area .comment-respond input[type="number"], .comments-area .comment-respond textarea {
        font-size: 14px;
    }
    blockquote, .blockquote {
        padding: 20px !important;

        p {
            font-size: 17px !important;
        }
    }
    .prev-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0;

        .prev-title {
            font-size: 16px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }
    .next-link-wrapper {
        flex: 0 0 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;

        .next-title {
            font-size: 16px;
        }
        .meta-wrapper {
            font-size: 13px;
        }
    }

    .social-link {
        justify-content: center !important;
        margin-top: 15px !important;
    }

    .footer-area {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .single-footer-widget {
        h3 {
            margin-bottom: 20px;
            font-size: 19px;
        }
        &.pl-4, &.px-4 {
            padding-left: 0 !important;
        }
    }
    .footer-bottom-area {
        text-align: center;
        margin-top: 30px;
        padding: {
            top: 25px;
            bottom: 25px;
        };
        p {
            line-height: 1.8;
        }
        .payment-types {
            margin-top: 15px;
            text-align: center;
        }
    }

    .go-top {
        right: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 24px;
    }
    .single-facility-box {
        h3 {
            font-size: 18px;
        }
    }

    .single-products-box, .products-box {
        .products-content {
            .price {
                opacity: 1 !important;
                visibility: visible !important;
            }
            .add-to-cart {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
                left: 17px;
                bottom: 85px;
                background: #f53f85;
                color: #fff !important;
                padding: 5px 15px;
                font-size: 12px;
                border-radius: 5px;
            }
        }
    }

    .sidebarModal.right {
        .modal-innter-content {
            width: 320px;
            .modal-body {
                padding: 30px;
            }
        }
        .close {
            right: 25px;
            top: 23px;
        }
    }

    .main-nav, .enterprise-nav {
        .navbar {
            .navbar-brand {
                font-size: 20px;
                img {
                    max-width: 100px;
                }
            }
        }
    }
    
    .grocery-main-banner {
        padding-top: 150px;
        padding-bottom: 150px;
        padding-left: 15px;
        padding-right: 15px;
        
        h1 {
            font-size: 33px;
        }
        p {
            font-size: 18px;
        }
    }
    .left-categories {
        .content {
            text-align: left;
        }
    }

    .furniture-banner {
        padding-top: 150px;
        padding-bottom: 150px;
    }
    .furniture-banner-content {
        margin-left: 0;
        padding-left: 15px;
        padding-right: 15px;
        h1 {
            font-size: 33px;
        }
        p {
            font-size: 18px;
        }
    }

    .jewelry-main-banner {
        padding-top: 100px;
        padding-bottom: 100px;
        padding-left: 15px;
        padding-right: 15px;
        .line {
            display: none;
        }
    }
    .jewelry-banner-content {
        padding: 0;
        .sub-title {
            font-size: 20px;
        }
        h1 {
            font-size: 35px;
        }
        p {
            font-size: 18px;
        }
    }

    .grocery-demo {
        .products-area {
            padding-top: 60px;
        }
    }

}

/* Min width 576px to Max width 767px */
@media only #{$media} and ($feature_min : $value_one) and ($feature_max : $value_two) {
    .mySwipera{
        h2{
            font-size: 24px !important;
        }
        h4{
            font-size: 16px !important;
        }
        i{
            font-size: 20px !important;
        }
        .banner-logo{
            width: 80px !important;
            height: 80px !important;
        }
    }
    .header-top-menu {
        display: inline-block;
    }
    .header-top-others-option {
        display: inline-block;
        position: relative;
        top: 3px;
        margin: {
            top: 0;
            left: 15px;
        };
    }

    .offer-content {
        max-width: 300px;
    }

    .blog-details-desc {
        .article-footer {
            text-align: left;
            
            .article-tags {
                flex: 0 0 50%;
                max-width: 50%;
            }
            .article-share {
                flex: 0 0 50%;
                max-width: 50%;

                .social {
                    text-align: right;
                    margin-top: 0;
                }
            }
        }
    }
    .comments-area {
        .comment-author {
            .avatar {
                position: absolute;
                display: inline-block;
                margin-bottom: 0;
                left: -65px;
            }
        }
        .comment-body {
            padding-left: 65px;
        }
    }

    .products-details-desc {
        .wishlist-compare-btn {
            .optional-btn {
                display: inline-block;

                &:not(:first-child) {
                    margin: {
                        left: 10px;
                        top: 0;
                    };
                }
            }
        }
    }

    .products-details-tab {
        .tabs {
            li {
                display: inline-block;
                margin-right: 0;
            }
        }
    }

}

/* Min width 991px to Max width 991px */
@media only #{$media} and ($feature_min : $value_three) and ($feature_max : $value_four) {


    .cards-grid-list-small{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .navbar-area {
        // padding-top: 10px;
        // padding-bottom: 10px;
        
        // .main-nav, .enterprise-nav {
        //     .navbar {
        //         padding: 0;
        //     }
        // }
    }
    .top-header {
        text-align: center;
        padding: {
            top: 15px;
            bottom: 15px;
        };
    }
    .top-header-discount-info {
        margin: {
            top: 10px;
            bottom: 10px;
        };
    }
    .header-top-menu {
        display: inline-block;
        text-align: center;
    }
    .header-top-others-option {
        display: inline-block;
        position: relative;
        top: 3px;
        margin: {
            top: 0;
            left: 15px;
        };
    }

   
    .main-banner {
        // height: auto;
        // padding: {
        //     top: 160px;
        //     bottom: 160px;
        // };
        &.banner-bg2 {
            background-position: top left;
        }
    }
    .main-banner-content {
        max-width: 700px;
        margin-left: auto;

        .sub-title {
            font-size: 16.5px;
        }
        h1 {
            font-size: 45px;
        }
        p {
            font-size: 16px;
        }
    }
    .banner-content {
        max-width: 690px;
        padding: 35px;

        .sub-title {
            font-size: 20px;
        }
        h1 {
            font-size: 50px;
            margin: 12px 0 15px;
        }
        p {
            font-size: 17px;
        }
    }
    .banner-image {
        margin-top: 30px;

        .circle {
            width: 290px;
            height: 290px;
        }
    }
    .hero-banner {
        padding: {
            top: 100px;
            bottom: 0;
        };
        .main-banner-content {
            text-align: center;
        }
    }

    .single-facility-box {
        h3 {
            font-size: 19px;
        }
    }

    .products-area {
        .woocommerce-widget-area {
            display: none;
        }
    }
    .single-products-box {
        .products-image {
            .sale-tag {
                bottom: 65px;
            }
        }
    }
    .products-box {
        .products-image {
            .sale-tag {
                bottom: 65px;
            }
        }
    }
    .products-filter-options {
        .sub-title {
            a {
                margin-right: 8px;
            }
        }
    }

    .about-content {
        padding-left: 0;
        margin-top: 30px;
    }
    .about-inner-area {
        margin-top: 20px;
    }
    .about-text {
        margin-top: 30px;
    }

    .faq-accordion-tab {
        .tabs {
            margin-bottom: 30px;

            li {
                margin-left: 6px;
                margin-right: 6px;
                padding: 15px 17px;
                font-size: 14px;
            }
        }
    }

    .coming-soon-content {
        margin: {
            left: auto;
            right: auto;
        };
    }

    .products-details-desc {
        margin-top: 30px;
    }

    .cart-table {
        table {
            thead {
                tr {
                    th {
                        padding: 0 20px 15px 0;
                    }
                }
            }
        }
    }

    .order-details {
        margin-top: 30px;
    }

    .contact-form {
        margin-top: 30px;
    }

    .main-nav {
        .navbar {
            .navbar-nav {
                .nav-item {
                    a {
                        // margin-left: 8px;
                        // margin-right: 8px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .single-categories-box {
        .content {
            h3 {
                font-size: 25px;
            }
        }
    }
    .section-title {
        h2 {
            font-size: 30px;
        }
    }

    .single-products-box, .products-box {
        .products-content {
            .price {
                opacity: 1 !important;
                visibility: visible !important;
            }
            .add-to-cart {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
                left: 17px;
                bottom: 85px;
                background: #f53f85;
                color: #fff;
                padding: 5px 15px;
                font-size: 12px;
                border-radius: 5px;
            }
        }
    }
}

@media only #{$media} and ($feature_min : 1200px) and ($feature_max : 1440px) {
.col-featured{
    -webkit-box-flex: 0;
    -ms-flex: 0 25%;
    flex: 0 25%;
    max-width: 25%;

}


.col-featured-side{
    -webkit-box-flex: 0;
    -ms-flex: 0 75%;
    flex: 0 75%;
    max-width: 75%;

}
}
@media only #{$media} and ($feature_min : 1441px) and ($feature_max : 1600px) {
    .col-featured{
        -webkit-box-flex: 0;
        -ms-flex: 0 23.2%;
        flex: 0 23.2%;
        max-width: 23.2%;
    
    }
    
    
    .col-featured-side{
        -webkit-box-flex: 0;
        -ms-flex: 0 76.8%;
        flex: 0 76.8%;
        max-width: 76.8%;
    
    }
}

@media only #{$media} and ($feature_min : 1600px) {
    .col-featured{
        -webkit-box-flex: 0;
        -ms-flex: 0 22%;
        flex: 0 22%;
        max-width: 22%;
    
    }
    
    
    .col-featured-side{
        -webkit-box-flex: 0;
        -ms-flex: 0 77.9%;
        flex: 0 77.9%;
        max-width: 77.9%;
    
    }
}

/* Min width 992px to Max width 1199px */
@media only #{$media} and ($feature_min : $value_five) and ($feature_max : $value_six) {

    .col-featured{
        -webkit-box-flex: 0;
        -ms-flex: 0 30%;
        flex: 0 30%;
        max-width: 30%;
    
    }


    .col-featured-side{
        -webkit-box-flex: 0;
        -ms-flex: 0 70%;
        flex: 0 70%;
        max-width: 70%;
    
    }

    .cards-grid-list-with-sidebar{
        display: grid;
        grid-template-columns: repeat(3, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
        .featured{
            grid-column: span 2; 
        }
    }
    .cards-grid-list-with-sidebar-small{
        display: grid;
        grid-template-columns: repeat(2, minmax(0px, 1fr));
        gap: 1rem 1rem;
        margin-bottom: 1rem;
        &.sidebar-active{

        }
    }

    .header-contact-info {
        margin-bottom: -5px;

        li {
            margin-bottom: 5px;
        }
    }
    .header-top-menu {
        margin-bottom: -5px;

        li {
            margin-bottom: 5px;
        }
    }

    // .main-banner {
    //     height: 700px;
    // }
    .main-banner-content {
        h1 {
            font-size: 50px;
        }
    }
    .banner-content {
        padding: 30px;
        max-width: 425px;

        .sub-title {
            font-size: 25px;
        }
        h1 {
            font-size: 50px;
        }
        p {
            font-size: 17px;
        }
    }

    .single-categories-box {
        .content {
            padding: 15px 15px 0;

            h3 {
                font-size: 22px;
            }
        }
    }
    .categories-box {
        .content {
            top: 10px;
            left: 10px;
            padding: {
                left: 20px;
                right: 20px;
            };
            h3 {
                font-size: 17px;
            }
        }
    }

    .coming-soon-content {
        margin: {
            left: auto;
            right: auto;
        };
    }
    .single-blog-post {
        .post-content {
            h3 {
                font-size: 20px;
            }
        }
    }

    .full-width-area {
        .products-box {
            .products-content {
                h3 {
                    font-size: 18px;
                }
            }
        }
    }
}

@media only #{$media} and ($feature_min : $value_seven) {


    .banner-content {
        max-width: 755px;
    }

}



@media only #{$media} and ($feature_max : $value_one) {
    .grid-three-columns{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        grid-gap: 1rem 1rem;
        gap: 0.5rem 0.5rem;
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    .grid-deals-page{
        display: grid;
        grid-template-columns: repeat(1, minmax(0px, 1fr));
        grid-gap: 1em;
        &.grid-deals-page-two-c{
            grid-template-columns: repeat(2, minmax(0px, 1fr)) !important;
            .weedzly-card{
                .badge{
                    font-size: 12px !important;
                }
                h3{
                    font-size: 18px !important;
                }
            }
        }
    }

}

// @media only #{$media} and ($feature_min : $value_nine) {

//     .cards-grid-list-with-sidebar{
//         display: grid;
//         grid-template-columns: repeat(5, minmax(0px, 1fr));
//         gap: 1rem 1rem;
//         margin-bottom: 1rem;
//         &.sidebar-active{

//         }
//     }


// }



.navbar-toggler{
    padding-left: 1rem !important;
    margin-left: -1rem !important;
    padding-right: 1rem !important;
}

select, input, textarea {
    font-size: 17px !important;
  }